import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { CinemaChainDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class CinemaChainApiClient extends BaseCRUDApiClient<CinemaChainDto> {
  baseUrl = this.config.apiEndpoint + '/cinema-chain';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }
}
